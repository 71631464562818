(function () {
    angular.module('informaApp')
        .component('featureCard', {
            templateUrl: 'components/new-feature-popup/feature-card-list/feature-card/template.html',
            controller: FeatureCard,
            bindings: {
                feature: '<',
                onOpenLinkClick: '<'
            }
        });

    function FeatureCard($element, NewFeatureFileTypes) {
        this.fileTypes = NewFeatureFileTypes;
    }
})();
